<template>
  <nav-header title="发布需求" :to="to"/>
  <div class="tip-image">
    <img src="../../assets/images/da-shu-ju-ti-shi.png" alt="">
  </div>
  <van-form @submit="onSubmit">
    <div class="bg-aaa">
      <van-cell-group class="" inset>
        <van-field
            required
            v-model="form.title"
            label="项目描述"
            placeholder="请输入项目描述……"
            type="textarea"
            rows="2"
            autosize
        />
        <van-field
            required
            :readonly="isAutoMobile"
            v-model="form.mobile"
            label="手机号"
            placeholder="请输入手机号"

        />
        <van-field
            required
            v-model="form.code"
            label="验证码"
            placeholder="请输入验证码"
            v-if="!isAutoMobile"
        >
          <template #button>
            <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
          </template>
        </van-field>
        <model-and-code/>

      </van-cell-group>

      <van-collapse v-model="activeNames">
        <van-collapse-item name="xiang-xi" title="">
          <template #value>详细信息</template>
        </van-collapse-item>
      </van-collapse>

      <van-cell-group inset v-if="activeNames.includes('xiang-xi')">
        <fu-wu-shang-type
            title="需求类型"
            input-type="radio"
            v-model:id="form.type_id"
            v-model:name="form.type_name"
        />
        <Address
            v-model:id="form.address_id"
            v-model:name="form.address_name"
        />

        <van-field
            v-model.number="form.money"
            label="预算金额"
            type="number"
            placeholder="请输入预算金额"
        />
        <model-and-code/>

      </van-cell-group>
    </div>
    <div class="form-btn">
      <van-button type="primary" @click="onSubmit" block round>
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import FuWuShangType from "@/components/select/FuWuShangType";
import Address from "@/components/select/Address";
import yanZhengMa from "@/views/open/yanZhengMa";
import http from "@/api/http";
import NavHeader from "@/components/top/NavHeader";
import din_shi from "@/api/din_shi";

export default {
  name: 'addXuQIu',
  components: {NavHeader, FuWuShangType, Address},
  mixins: [yanZhengMa],
  data() {
    return {
      to: {name: 'Index'},
      form: {},
      activeNames: [],
      pageStatus: false
    }
  },
  methods: {
    show() {
      alert('ok')
      this.pageStatus = true
      this.getMobiel().then(mobile => {
        this.form.mobile = mobile
      })
    },
    onSubmit() {
      http.post("/gzhphp/fu_wu_shang/addXuQiu", this.form, {
        load_success: true
      }).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(da => {
            this.$router.push(this.to)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.tip-image {
  margin: 10px;
  text-align: center;

  img {
    width: 70%;
    margin: auto;
    max-width: 400px;
  }
}
</style>
